//заявки на комплексную модернизацию

import api from "@/modules/api";
import {get, post} from "@/modules/remote";


/**
 * Тип "Регион"
 *
 * @typedef {Object} Region
 * @property {string} code
 * @property {string} name
 * @property {number} year
 */



/**
 * Тип "Файл с uuid"
 * @typedef {Object} UuidBasedStoredFile
 * @property {string} id
 * @property {string} userName
 * @property {string} name
 * @property {string} meta
 * @property {string} deletedDateTime
 * @property {string} createdDateTime
 * @property {boolean} isPublic
 */

/**
 * Тип "Заполненное поле заявки на комплексную модернизацию"
 *
 * @typedef {Object} ApplicationValue
 * @property {Number} id
 * @property {Application} application
 * @property {number} sectionId
 * @property {number} parameterId
 * @property {string} comment
 * @property {Array.<UuidBasedStoredFile>} attachments
 */

/**
 * Тип "Заявка на комплексную модернизацию"
 *
 * @typedef {Object} Application
 * @property {Number} id
 * @property {string} regionCode
 * @property {Array.<ApplicationValue>} valueList
 * @property {'DRAFT' | 'APPLIED' | 'EDIT' | 'APPROVED'} status
 * @property {string} creationDateTime
 * @property {string} modificationDateTime
 */

/**
 * Тип "Параметр заявки на комплексную модернизацию"
 *
 * @typedef {Object} ApplicationParameter
 * @property {number} id
 * @property {string} name
 */


/**
 * Тип "Раздел заявки на комплексную модернизацию"
 *
 * @typedef {Object} ApplicationSection
 * @property {number} id
 * @property {string} name
 * @property {Array.<ApplicationParameter>} version
 */


/**
 * Тип "Конфигурация заявки на комплексную модернизацию"
 *
 * @typedef {Object} ApplicationConfig
 * @property {Array.<ApplicationSection>} sections
 */

export const applicationStatuses = {
    DRAFT: 'Черновик',
    APPLIED: 'Заявка подана',
    EDIT: 'Заявка корректируется',
    APPROVED: 'Заявка одобрена'
}

export const requestStatuses = {
    DRAFT: 'Черновик',
    SENT: 'Заявка подана',
    EDITED: 'Заявка корректируется',
    APPROVED: 'Заявка одобрена'
}

export const reportStatuses = {
    DRAFT: 'Черновик',
    SENT: 'Отправлено',
}

/**
 *
 * @returns {Promise<Array.<Application>>}
 */
export async function getAllApplications() {
    return get(`/applications/`)
}

/**
 * @param {number} applicationId - id заявки
 * @returns {Promise<Application>}
 */
export async function getApplicationById(applicationId) {
    return get(`/applications/${applicationId}`)
}

/**
 * @param {string} regionCode - код региона, чью заявку ищем
 * @returns {Promise<Array.<Application>>}
 */
export async function getApplicationsByRegionCode(regionCode) {
    return get(`/applications/findByRegionCode/${regionCode}`)
}


/**
 * @param {Application} application - заявка, которую нужно сохранить
 * @returns {Promise<Application>}
 */
export async function saveApplication(application) {
    return post(`/applications/`, application, null)
}

/**
 * обновить список значений показателей заявки
 * все старые значения будут удалены и заменены новыми
 * @param {number} applicationId - id заявки, чьи значения сохраняются
 * @param {Array.<ApplicationValue>} valueList - значения, которые нужно сохранить
 */
export async function saveApplicationValues(applicationId, valueList) {
    return post(`/applications/${applicationId}/values`, valueList, null)
}

/**
 *
 * @param {Region} region -  регион, чья заявка создается
 * @returns {Promise<Application>}
 */
export async function createEmptyApplication(region) {
    /** @type {Application} */
    const application = {}
    application.region = region
    application.status = 'DRAFT'
    application.valueList = []
    return saveApplication(application)
}

/**
 *
 * @returns {Promise<ApplicationConfig>}
 */
export async function getApplicationsConfig() {
    //return await get(`/applications/config`)
    return {
        "version": "1.0",
        "sections": [
            {
                "id": 1,
                "name": "Документы, прилагаемые к заявке для отбора на первом этапе",
                "parameters": [
                    {
                        "id": 1,
                        "name": "Гарантийное письмо, подписанное высшим должностным лицом субъекта Российской Федерации (руководителем высшего исполнительного органа субъекта Российской Федерации), о готовности выделения необходимого объема финансовых средств с учетом уровня софинансирования, установленного для реализации регионального проекта и доведении уровня заработной платы сотрудников центров занятости населения до уровня среднего дохода от трудовой деятельности в субъекте Российской Федерации"
                    },
                    {
                        "id": 2,
                        "name": "Копия нормативного правового акта высшего исполнительного органа субъекта Российской Федерации о создании (реорганизации) юридического лица, включающего структурные подразделения: центры занятости населения в зависимости от численности населения в трудоспособном возрасте; подразделения, осуществляющие централизованное управление функционированием центров занятости населения"
                    },
                    {
                        "id": 3,
                        "name": "Пояснительная записка к региональному проекту (по образцу в соответствии с Приложением 1 к Приказу Минтруда России от 12 декабря 2022 г. № 778 в редакции от 30.06.2023)"
                    },
                    {
                        "id": 4,
                        "name": "Приложение 1 к Пояснительной записке к региональному проекту «Информация по составу сети ЦЗН» (по образцу в соответствии с Приложением 1 к Приказу Минтруда России от 12 декабря 2022 г. № 778 в редакции от 30.06.2023)"
                    },
                    {
                        "id": 5,
                        "name": "Приложение 2 к Пояснительной записке к региональному проекту «Информация по имущественному комплексу сети ЦЗН» (по образцу в соответствии с Приложением 1 к Приказу Минтруда России от 12 декабря 2022 г. № 778 в редакции от 30.06.2023)"
                    },
                    {
                        "id": 6,
                        "name": "Презентация регионального проекта (по образцу в соответствии с Приложением 2 к Приказу Минтруда России от 12 декабря 2022 г. № 778 в редакции от 30.06.2023)"
                    },
                    {
                        "id": 7,
                        "name": "Расчет и обоснование затрат на проведение текущего ремонта зданий и помещений центров занятости населения, включая приведение к фирменному стилю с учетом средней стоимости текущего ремонта 1 кв. м, а также затрат на приобретение оборудования"
                    }
                ]
            },
            {
                "id": 2,
                "name": "Документы, прилагаемые к заявке для отбора на втором этапе",
                "parameters": [
                    {
                        "id": 1,
                        "name": "Проект нормативного правового акта об утверждении плана мероприятий"
                    }
                ]
            },
            {
                "id": 3,
                "name": "Дополнительные документы",
                "parameters": [
                    {
                        "id": 1,
                        "name": "Фото центров занятости населения и планировка помещений по каждой точке присутствия (Паспорт ЦЗН)."
                    },
                    {
                        "id": 2,
                        "name": "Информация по этажности помещений центров занятости населения по каждой точке присутствия"
                    },
                    {
                        "id": 3,
                        "name": "Информация по арендованным помещениям с указанием сроков аренды и наименования арендодателей по каждой точке присутствия центров занятости населения"
                    }
                ]
            }
        ]
    }
}

export async function uploadFile(file, meta) {
    if (file.size > 52428800) {
        let err = 'Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером'
        alert(err)
        console.log(err)
        return null
    }
    let formData = new FormData();
    formData.append('file', file);
    if (meta) {
        formData.append('meta', meta)
    }
    let req = await api.postFile('/uuidfs/', {}, formData, 'POST');
    console.log('req', req)
    if (req.ok) {
        console.log('upload ok');
        return req.payload
    } else {
        alert('Ошибка загрузки файла: ' + req.error)
        console.log('upload error', req.error)
    }
}

export async function downloadFile(fileId, fileName) {
    let req = await api.download("/uuidfs/" + fileId, {});
    if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    } else {
        console.log('download error');
        alert('Ошибка загрузки файла')
    }
}
